import React from "react";
import { HelixLoader } from "../../loaders/helix";

export default function Loader({ loading, top = "0px" }) {
  return (
    loading && (
      <div
        className="absolute inset-0 flex items-center justify-center z-[2000]"
        style={{
          backgroundColor: "rgba(244, 243, 236, .1)",
          backdropFilter: "blur(10px)",
          top,
        }}
      >
        <HelixLoader />
      </div>
    )
  );
}
