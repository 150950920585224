import CtaButton from "@/modules/common/components/cta-button";
import Modal from "@/modules/common/components/modal";
import React from "react";

export default function GetStartedModal({ isModalOpen, closeModal }) {
  return (
    <Modal isOpen={isModalOpen} onClose={closeModal}>
      <p className="color-customGray text-3xl font-sans font-normal leading-tight tracking-tight text-center mb-8">
        Join the Geneseq Marketplace as a licensee or vendor.
      </p>
      <div className="flex flex-row w-full justify-center space-x-5">
        <CtaButton href={"/licensee/register"} px={10} onClick={closeModal}>
          Start as Licensee
        </CtaButton>
        <CtaButton href={"/vendor/register"} px={10} onClick={closeModal}>
          Start as Vendor
        </CtaButton>
      </div>
    </Modal>
  );
}
