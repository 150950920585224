"use client";
import styles from "./helixstyles.module.scss";

export const HelixLoader = () => {
  return (
    <div className="flex justify-center items-center h-96">
      <div className={styles.container}>
        <div className={styles.slice} />
        <div className={styles.slice} />
        <div className={styles.slice} />
        <div className={styles.slice} />
        <div className={styles.slice} />
        <div className={styles.slice} />
      </div>
    </div>
  );
};
